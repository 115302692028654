<template>
  <b-card>
    <FormView
      :data="$store.getters['Game/detailData']"
      :fields="fields"
      @load="loadData"
    />
  </b-card>
</template>

<script>
import FormView from '@/layouts/components/FormView.vue'

import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    FormView,
  },
  data() {
    return {
      // Fields
      fields: [
        {
          key: 'id',
          type: 'id',
          label: 'ID',
        },
        {
          key: 'image',
          label: 'Cover',
          type: 'image',
        },
        {
          key: 'name',
          label: 'Title',
          type: 'profile',
          image: 'icon',
        },
        {
          key: 'active_status',
          label: 'Status',
          type: 'dot',
          map: {
            active: 'success',
            inactive: 'danger',
          },
        },
        {
          key: 'platform',
          label: 'Platform',
          type: 'badges',
        },
        {
          key: 'sort',
          label: 'Order',
          prefix: '#',
        },
        {
          key: 'createdAt',
          label: 'Created at',
          type: 'datetime',
        },
        {
          key: 'updatedAt',
          label: 'Updated at',
          type: 'datetime',
          visibility: ['view'],
        },
      ],
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('Game/getDetail', id)
    },
  },
}
</script>

<style scoped>

</style>
